import React, { Fragment, useState } from "react";

function Circle({ userSelected, setUserSelected, position }) {
  const data = infoUser(userSelected);
  const bigCircle = data.bigCircle;
  const smallCircle = data.smallCircle;

  return (
    <div className={`-space-y-8 sm:-space-y-14 xl:-space-y-20 ${position}`}>
      <button onClick={() => setUserSelected(userSelected)}>
        <img className="w-20 h-20 sm:w-36 sm:h-36 xl:w-52 xl:h-52 border-4 shadow-2xl border-gray-50 rounded-full dark:border-gray-800" src={require(`../assets/images/${bigCircle}.jpg`)} alt="Créditos" />
      </button>
    
        <img className="w-8 h-8 sm:w-16 sm:h-16 xl:w-24 xl:h-24 rounded-full" src={require(`../assets/images/${smallCircle}.png`)} alt="Créditos" />
   
    </div>
  );
}

function CircleWithDescription({ userSelected, position }) {
  const data = infoUser(userSelected);

  const bigCircle = data.bigCircle;
  const smallCircle = data.smallCircle;
  const name = data.name;
  const description = data.description;

  return (
    <div className={`-space-y-8 sm:-space-y-14 xl:-space-y-20 ${position}`}>
      <div className="bg-gray-100 flex rounded-full items-center shadow-xl">
        <img className="w-20 h-20 sm:w-36 sm:h-36 xl:w-52 xl:h-52 border-4 shadow-2xl border-gray-50 rounded-full dark:border-gray-800 " src={require(`../assets/images/${bigCircle}.jpg`)} alt="Monte" />
        <div className="pr-20 text-left">
          <p className="font-bold">{name}</p>
          <p>{description}</p>
        </div>
      </div>
      <img className="w-8 h-8 sm:w-16 sm:h-16 xl:w-24 xl:h-24 rounded-full" src={require(`../assets/images/${smallCircle}.png`)} alt="Monte" />
    </div>
  );
}

function infoUser(userSelected) {
  const users = {
    1: {
      bigCircle: "photo_julian",
      smallCircle: "role_julian",
      name: "José Julián Carvajal Vargas",
      description: "Director Ejecutivo",
    },
    2: {
      bigCircle: "photo_salome",
      smallCircle: "role_salome",
      name: "Salomé Gallego Arbeláez",
      description: "Dirección de Ingeniería",
    },
    3: {
      bigCircle: "danny_photo",
      smallCircle: "role_danny",
      name: "Danny Javier Vásquez Cerón",
      description: "Desarrollo de software",
    },
    4: {
      bigCircle: "photo_argemiro",
      smallCircle: "role_argemiro",
      name: "Argemiro Hernández Hernández",
      description: "Contador",
    },
  };
  return users[userSelected];
}

function OrderCircle({ userSelected, setUserSelected }) {
  if (userSelected === 1) {
    return (
      <div className="flex flex-col md:flex-row md:-space-x-5 xl:-space-x-12">
        <CircleWithDescription userSelected={userSelected} position={"z-0"} />
        <Circle userSelected={2} setUserSelected={setUserSelected} position={"z-10"} />
        <Circle userSelected={3} setUserSelected={setUserSelected} position={"z-20"} />
        <Circle userSelected={4} setUserSelected={setUserSelected} position={"z-30"} />
        
      </div>
    );
  } else if (userSelected === 2) {
    return (
      <div className="flex flex-col md:flex-row md:-space-x-5 xl:-space-x-12">
        <Circle userSelected={1} setUserSelected={setUserSelected} position={"z-0"} />
        <CircleWithDescription userSelected={userSelected} position={"z-10"} />
        <Circle userSelected={3} setUserSelected={setUserSelected} position={"z-20"} />
        <Circle userSelected={4} setUserSelected={setUserSelected} position={"z-30"} />
        
      </div>
    );
  } else if (userSelected === 3) {
    return (
      <div className="flex flex-col md:flex-row md:-space-x-5 xl:-space-x-12">
        <Circle userSelected={1} setUserSelected={setUserSelected} position={"z-0"} />
        <Circle userSelected={2} setUserSelected={setUserSelected} position={"z-10"} />
        <CircleWithDescription userSelected={userSelected} position={"z-20"} />
        <Circle userSelected={4} setUserSelected={setUserSelected} position={"z-30"} />
        
      </div>
    );
  } else if (userSelected === 4) {
    return (
      <div className="flex flex-col md:flex-row md:-space-x-5 xl:-space-x-12">
        <Circle userSelected={1} setUserSelected={setUserSelected} position={"z-0"} />
        <Circle userSelected={2} setUserSelected={setUserSelected} position={"z-10"} />
        <Circle userSelected={3} setUserSelected={setUserSelected} position={"z-20"} />
        <CircleWithDescription userSelected={userSelected} position={"z-30"} />
        
      </div>
    );
  } 
}
const Credits = () => {
  const [userSelected, setUserSelected] = useState(1);

  return (
    <Fragment>
      <section className="bg-center bg-cover bg-no-repeat bg-credits_image text-white">
        <div className="h-full py-28 sm:py-32 lg:py-52 2xl:py-72 flex items-center pl-20 bg-gradient-to-r from-black">
          <p className="text-3xl lg:text-7xl font-bold">Créditos Sitio Web</p>
        </div>
      </section>

      <section className="dark:bg-dark">
        <div className="flex justify-center py-10 lg:py-20">
          <OrderCircle userSelected={userSelected} setUserSelected={setUserSelected} />
        </div>
      </section>
    </Fragment>
  );
};

export default Credits;
