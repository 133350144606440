import React, { Fragment } from 'react';

const Footer = ({ theme }) => {



    return (
        <Fragment>
            <div className="bg-gray-50 dark:bg-semi-dark dark:text-white py-7 lg:py-14 text-sm lg:text-xl">
                <footer className="grid lg:grid-cols-3 container mx-auto border-b border-gray-300 pb-9 gap-y-8">
                    <div className="flex justify-center items-center">
                        <div className="text-center lg:text-left">
                            <div className="flex justify-center lg:justify-start">
                                <img src={(theme === 'dark') ? require("../assets/images/logo_cide_white.png") : require("../assets/images/logo_cide.png")} alt="Henry Ford" className="h-14" />
                            </div>
                            <p>Manizales - Colombia</p>
                            <p>Carrera 24 No 47-34 1004</p>
                            <div>
                                <a href="tel:+573014709052" className="flex items-center space-x-2">
                                    <p>+57 301 470 9052</p>
                                    <button className="bg-green-500 rounded-md p-1 flex lg:hidden font-semibold text-white items-center space-x-1">
                                        <p>Llamar</p>
                                        <i className="fa-solid fa-phone"></i>
                                    </button>
                                </a>
                            </div>
                            <p><a href="mailto:gcomercial@cide.org.co">gcomercial@cide.org.co</a></p>
                        </div>
                    </div>

                    <div className="flex justify-center items-center">
                        <div className="rounded-xl overflow-hidden shadow-lg">
                            <div className="relative text-right">
                                <iframe title="map" className="lg:w-70 lg:h-44" src="https://maps.google.com/maps?q=5.064676308386436,%20-75.50071597049589&t=&z=17&ie=UTF8&iwloc=&output=embed" />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center items-center">
                        <div className="text-center lg:text-left">
                            <p><a href="https://www.politicadeprivacidad.co/politica/cide">Trataminento de datos personales</a></p>
                            <p><a href="/aviso-de-cookies">Aviso de cookies</a></p>
                            <p><a href="/terminos-de-uso">Términos de uso</a></p>
                            <p><a href="/regime-tributario-especial">Régimen Tributario Especial</a></p>
                            <p><a href="/creditos-sitio-web">Créditos Sitio Web</a></p>
                        </div>

                    </div>
                </footer>
                <div className="container mx-auto flex flex-col lg:flex-row gap-y-7 text-center text-xs lg:text-sm mt-7">
                    <p className="w-full lg:w-3/5 lg:text-left">Copyrigth © 2023 - Centro de Integración y Desarrollo Empresarial - Todos los derechos reservados</p>
                    <p className="w-full lg:w-2/5 lg:text-right">VIGILADOS GOBERNACIÓN DE CALDAS</p>
                </div>
            </div>
        </Fragment>


    )
}

export default Footer;