import React, { Fragment } from "react";
import TableRow from "../components/TableRow";

const RegimenTributario = () => {

    const rows = [
        {
            title: "Certificación pagos directivos",
            link: require("../assets/documents/2024/1_CERTIFICACION_PAGOS_DIRECTIVOS.pdf"),
            fileName: "CIDE_CERTIFICACION_PAGOS_DIRECTIVOS.pdf",
        },
        {
            title: "Certificación requisitos numeral 13 del parágrafo 2 del artículo 364-5 ET",
            link: require("../assets/documents/2024/2_CERTIFICACION_REQUISITOS_NUMERAL_13_DEL_PARAGRAFO_2_DEL_ARTICULO_364-5_ET.pdf"),
            fileName: "CIDE_CERTIFICACION_REQUISITOS_NUMERAL_13_DEL_PARAGRAFO_2_DEL_ARTICULO_364-5_ET.pdf",
        },
        {
            title: "Certificación requisitos numeral 3 del artículo 364-3 ET",
            link: require("../assets/documents/2024/7_CERTIFICACION_REQUISITOS_NUMERAL_3_DEL_ARTICULO_364-3_ET.pdf"),
            fileName: "CIDE_CERTIFICACION_REQUISITOS_NUMERAL_3_DEL_ARTICULO_364-3_ET.pdf",
        },
        {
            title: "Acta de asamblea",
            link: require("../assets/documents/2024/CIDE_ACTA_DE_ASAMBLEA.pdf"),
            fileName: "CIDE_ACTA_DE_ASAMBLEA.pdf",
        },
        {
            title: "Estados financieros 2023",
            link: require("../assets/documents/2024/CIDE_ESTADOS_FINANCIEROS_2023.pdf"),
            fileName: "CIDE_ESTADOS_FINANCIEROSROS_2023.pdf",
        },
        {
            title: "Informe de gestión 2023",
            link: require("../assets/documents/2024/CIDE_INFORME_DE_GESTION_2023.pdf"),
            fileName: "CIDE_INFORME_DE_GESTION_2023.pdf",
        },
        {
            title: "Renta AG 2023",
            link: require("../assets/documents/2024/CIDE_RENTA_AG_2023.pdf"),
            fileName: "CIDE_RENTA_AG_2023.pdf",
        },
        {
            title: "RTF AG 2024",
            link: require("../assets/documents/2024/CIDE_RTF_AG_2024.pdf"),
            fileName: "CIDE_RTF_AG_2024.pdf",
        }
    ];

    return (
        <Fragment>
            <section className="bg-center bg-cover bg-no-repeat bg-regimen_image text-white">
                <div className="h-full py-28 sm:py-32 lg:py-52 2xl:py-72 flex items-center pl-20 bg-gradient-to-r from-black">
                    <p className="text-3xl lg:text-7xl font-bold">
                        Régimen Tributario <br />
                        Especial
                    </p>
                </div>
            </section>

            <section className="bg-white dark:bg-dark dark:text-white">
                <article className="container mx-auto">
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="overflow-hidden rounded-lg">
                                    <table className="min-w-full">
                                        <thead className="border-b bg-gray-200 dark:bg-semi-dark">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="text-sm font-medium px-6 py-4 text-center lg:w-1/2"
                                                >
                                                    Documento
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-sm font-medium px-6 py-4 text-center"
                                                >
                                                    2024
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    title={row.title}
                                                    link={row.link}
                                                    fileName={row.fileName}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>
        </Fragment>
    );
};

export default RegimenTributario;
