import React, { Fragment } from 'react'
import BannerHelp from '../components/BannerHelp'
import SportAcordion from '../components/SportAcordion'

const Sport = ({ theme }) => {
    return (
        <Fragment>
            <section className="h-screen bg-center bg-cover bg-no-repeat bg-sport_image text-white">
                <div className="h-full py-12 flex items-center pl-20 bg-gradient-to-r from-black">
                    <p className="text-3xl lg:text-7xl font-bold">Ciencia, Tecnología <br />e Innovación que <br />genera Bienestar Social</p>
                </div>
            </section>

            <section className="bg-white dark:bg-dark dark:text-white py-8 lg:py-14">
                <div className="flex flex-col lg:flex-row items-center justify-center gap-5">
                    <div className="flex flex-col lg:w-1/5 justify-center items-center">
                        <div className="h-20 w-20 rounded-full overflow-hidden">
                            <img src={require('../assets/images/michael_jordan.jpg')} alt="Michael Jordan" className="h-full w-full" />
                        </div>
                        <p className="font-bold">Michael Jordan</p>
                        <p className="text-center">Ex jugador de baloncesto</p>
                    </div>
                    <p className="text-sm lg:text-3xl font-bold lg:w-2/5 text-center">
                        "Convierte siempre una situación negativa en una positiva"
                    </p>
                    <div className="h-14 lg:h-24 lg:w-1/5 flex justify-center">
                        <img src={(theme === 'dark') ? require('../assets/images/dark/firma_michael_jordan_white.png') : require('../assets/images/firma_michael_jordan.png')} alt="Firma Michael Jordan" className="h-full w-full" />
                    </div>
                </div>
            </section>

            <section>
                <div className="bg-gray-50 dark:bg-semi-dark dark:text-white flex justify-center p-8 lg:py-14">
                    <p className="w-full lg:w-3/5 text-sm lg:text-3xl text-center">
                        En CIDE creemos que el deporte nos invade de actitud positiva y mantener una actitud positiva frente a las adversidades y los obstáculos, es la clave del logro
                    </p>
                </div>
            </section>

            <section className="bg-white dark:bg-dark overflow-hidden py-7 lg:py-14">
                <div className="container mx-auto pr-6 pl-3 sm:pr-0 sm:pl-0">
                    <SportAcordion theme={theme} />
                </div>
            </section>

            <section>
                <BannerHelp />
            </section>
        </Fragment>
    )
}

export default Sport