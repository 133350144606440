import { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <div className={`${id === open ? "bg-blue-cide text-white" : "bg-gray-100 text-black"} h-9 w-9 rounded-full flex items-center font-light justify-center transition-transform`}>
      {open === id ? <i className="fa-solid fa-xmark"></i> : <i className="fas fa-plus"></i>}
    </div>
  );
}

function Body({ id }) {
  if (id === 1) {
    return (
      <div className="font-century-gothic text-sm lg:text-lg text-black dark:text-white space-y-5 text-right">
        <h1 className="font-bold texl-xl lg:text-4xl">Sanar</h1>
        <p>
          En conjunto con BIOS, MinTIC y con expertos en conceptos avanzados de Analítica de Datos construimos el sistema SANAR, una poderosa herramienta para el sector de la Salud en Colombia, que permite mejorar la calidad de la información que se
          origina desde los RIPS (Registros Individuales de Prestación de Servicios de Salud), orientado a entregar datos que permitan efectuar toma de decisiones más acertadas en las políticas públicas de salud en el país
        </p>

        <div className="flex sm:justify-between flex-col sm:flex-row text-xl gap-y-3">
          <div className="flex space-x-6">
            <p>
              Medios&nbsp;&nbsp;<i className="fa-solid fa-book"></i>
            </p>
            <div className="space-x-5 animate-pulse">
              <a href="https://www.linkedin.com/in/sistema-sanar-687b3b157/" className="hover:font-bold hover:text-blue-cide" title="Linkedin Sanar" target="_blank" rel="noreferrer">
                1
              </a>
            </div>
          </div>

          <p className="sm:text-right">
            <a href="/#formulario-contacto" className="underline hover:text-blue-cide font-bold">
              Más información
            </a>
          </p>
        </div>

        <div className="flex justify-center">
          <div className="flex -space-x-12 h-52">
            <img className="w-40 h-40 border-4 shadow-2xl border-white rounded-full dark:border-gray-800 object-cover object-left" src={require("../assets/images/funec_1.jpg")} alt="Sanar" />
            <div className="flex items-end">
              <img className="w-40 h-40 border-4 border-white rounded-full dark:border-gray-800 z-0" src={require("../assets/images/medicine_2.jpg")} alt="Sanar" />
            </div>
            <img className="w-40 h-40 border-4 shadow-2xl border-white rounded-full dark:border-gray-800" src={require("../assets/images/medicine_1.jpg")} alt="Sanar" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="font-century-gothic text-sm lg:text-lg text-black dark:text-white space-y-5 text-right">
        <h1 className="font-bold texl-xl lg:text-4xl">Kuratium</h1>
        <p>Nos encontramos trabajando en una herramienta que ayudará a miles de personas en todo el país a regular su consumo farmacológico, de tal forma que impacte positivamente en su salud.</p>

        <div>
          <p className="sm:text-right">
            <a href="/#formulario-contacto">
              <button className="bg-blue-cide hover:bg-blue-700 rounded-full py-1 px-4 text-white font-semibold text-sm lg:text-lg">Quiero apoyar el proyecto</button>
            </a>
          </p>
        </div>

        <div className="flex justify-center">
          <div className="flex -space-x-12 h-52">
            <img className="w-40 h-40 border-4 shadow-2xl border-white rounded-full dark:border-gray-800 object-cover object-left" src={require("../assets/images/medicine_3.jpg")} alt="Kuratium" />
            <div className="flex items-end">
              <img className="w-40 h-40 border-4 border-white rounded-full dark:border-gray-800 z-0" src={require("../assets/images/medicine_4.jpg")} alt="Kuratium" />
            </div>
            <img className="w-40 h-40 border-4 shadow-2xl border-white rounded-full dark:border-gray-800" src={require("../assets/images/medicine_5.jpg")} alt="Kuratium" />
          </div>
        </div>
      </div>
    );
  }
}

export default function HealthAcordion({ theme }) {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? value : value);
  };

  return (
    <Fragment>
      <div className="flex flex-col lg:flex-row w-full h-full gap-x-5">
        <section className="w-full lg:w-2/5 space-y-5">
          <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
            <section className="shadow-lg rounded-lg px-8 border-2 border-gray-100">
              <AccordionHeader onClick={() => handleOpen(1)}>
                <div className="flex items-center space-x-5 h-full sm:h-32 w-full my-4">
                  <div className="w-1/3 flex justify-center">
                    <img src={theme === "dark" ? require("../assets/images/dark/logo_sanar_white.png") : require("../assets/images/logo_sanar.png")} alt="Sanar" className="w-full" />
                  </div>
                  <div className="h-full w-2/3">
                    <h1 className="text-black dark:text-white text-xl lg:text-3xl font-century-gothic text-left">Sanar</h1>
                    <p className="text-black dark:text-white text-sm lg:text-base font-century-gothic text-left font-light">Sistema con Analítica de Datos para evaluación inteligente de RIPS</p>
                  </div>
                </div>
              </AccordionHeader>
            </section>
            <section className="block lg:hidden my-4">
              <AccordionBody>
                <Body id={1} />
              </AccordionBody>
            </section>
          </Accordion>

          <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
            <section className="shadow-lg rounded-lg px-8 border-2 border-gray-100">
              <AccordionHeader onClick={() => handleOpen(2)}>
                <div className="flex items-center space-x-5 h-full sm:h-32 w-full my-4">
                  <div className="h-full w-2/3">
                    <h1 className="text-black dark:text-white text-xl lg:text-3xl font-century-gothic text-left">Kuratium</h1>
                    <p className="text-black dark:text-white text-sm lg:text-base font-century-gothic text-left font-light">Herramienta para regular el consumo farmacológico</p>
                  </div>
                </div>
              </AccordionHeader>
            </section>

            <section className="block lg:hidden my-4">
              <AccordionBody>
                <Body id={2} />
              </AccordionBody>
            </section>
          </Accordion>
        </section>

        <section className="w-full lg:w-3/5 h-full">
          <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
            <div className="hidden lg:block h-full">
              <AccordionBody>
                <Body id={1} />
              </AccordionBody>
            </div>
          </Accordion>
          <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
            <div className="hidden lg:block">
              <AccordionBody>
                <Body id={2} />
              </AccordionBody>
            </div>
          </Accordion>
        </section>
      </div>
    </Fragment>
  );
}
