import React, { Fragment } from 'react'
import BannerHelp from '../components/BannerHelp';
import HealthAcordion from '../components/HealthAcordion';  

const Health = ({ theme }) => {
    return (
        <Fragment>
            <section className="h-screen bg-center bg-cover bg-no-repeat bg-health_image text-white">
                <div className="h-full py-12 flex items-center pl-20 bg-gradient-to-r from-black">
                    <p className="text-3xl lg:text-7xl font-bold">Ciencia, Tecnología <br />e Innovación que <br />genera Bienestar Social</p>
                </div>
            </section>

            <section className="bg-gray-50 dark:bg-semi-dark dark:text-white py-8 lg:py-14">
                <div className="flex flex-col lg:flex-row items-center justify-center gap-5">
                    <div className="flex flex-col lg:w-1/5 justify-center items-center">
                        <div className="h-20 w-20 rounded-full overflow-hidden">
                            <img src={require('../assets/images/mah.jpg')} alt="Mahatma Gandhi" className="h-full w-full" />
                        </div>
                        <p className="font-bold">Mahatma Gandhi</p>
                        <p className="text-center px-5 md:px-0">Dirigente del movimiento de
                            independencia de la India</p>
                    </div>
                    <p className="text-sm lg:text-3xl font-bold lg:w-2/5 text-center px-5 md:px-0" >
                        "Es la salud el bien más preciado. Y no el oro o la plata"
                    </p>
                    <div className="h-14 lg:h-24 lg:w-1/5 flex justify-center">
                        <img src={(theme === 'dark') ? require('../assets/images/dark/firma_mah_white.png') : require('../assets/images/firma_mah.png')} alt="Firma Henry Ford" className="h-full w-full lg:w-2/3" />
                    </div>
                </div>
            </section>

            <section>
                <div className="bg-white dark:bg-dark dark:text-white flex justify-center p-8 lg:py-14">
                    <p className="w-full lg:w-3/5 text-sm lg:text-3xl text-center">
                        Para el bienestar social es indispensable que la comunidad goce de buena salud, por eso CIDE no duda en enfocar esfuerzos en la implementación de proyectos que modernicen este pilar tan importante de la sociedad
                    </p>
                </div>
            </section>

            <section className="bg-gray-50 dark:bg-semi-dark overflow-hidden py-7 lg:py-14">
                <div className="container mx-auto pr-6 pl-3 sm:pr-0 sm:pl-0">
                    <HealthAcordion theme={theme} />
                </div>
            </section>
            
            <section>
                <BannerHelp />
            </section>
        </Fragment>
    )
}

export default Health;