import { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <div className={`${id === open ? "bg-blue-cide text-white" : "bg-gray-100 text-black"} h-9 w-9 rounded-full flex items-center font-light justify-center transition-transform`}>
      {open === id ? <i className="fa-solid fa-xmark"></i> : <i className="fas fa-plus"></i>}
    </div>
  );
}

export default function EducationAcordion({ theme }) {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  /* 
    // para botones 1,2,3,4,5,6,7
    const [varMediateca, setVarMediateca] = useState(1);

    const handleMediateca = (value) => {
        setVarMediateca(varMediateca === value ? 0 : value);
    }; */

  return (
    <Fragment>
      <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
        <AccordionHeader onClick={() => handleOpen(1)}>
          <div className="flex items-center space-x-5 h-20 sm:h-28 w-full">
            <div className="w-1/3 lg:w-1/5 flex justify-center">
              <img src={theme === "dark" ? require("../assets/images/dark/logo_mediateca_white.png") : require("../assets/images/logo_mediateca.png")} alt="Mediateca" className="w-3/5" />
            </div>
            <h1 className="text-black dark:text-white text-xl lg:text-3xl font-century-gothic">Mediatecas</h1>
          </div>
        </AccordionHeader>
        <AccordionBody>
          <div className="font-century-gothic text-sm lg:text-lg text-black dark:text-white space-y-5">
            <p>
              Las <b className="font-bold">Mediatecas</b> implementadas por CIDE con el apoyo decidido de la Gobernación de Caldas, se constituyen en un aporte esencial para reducir la brecha digital, acercando la comunidad a estos ambientes
              especiales donde la tecnología juega un papel primordial para promover la lectura, el estudio de la robótica y otras ciencias
            </p>

            <div className="flex sm:justify-between flex-col sm:flex-row text-xl gap-y-3">
              <div className="flex space-x-6">
                <p>
                  Medios&nbsp;&nbsp;<i className="fa-solid fa-book"></i>
                </p>
                <div className="space-x-5 animate-pulse">
                  <a href="https://m.facebook.com/watch/?v=844072479342854" className="hover:font-bold hover:text-blue-cide" title="video Facebook: Mediatecas" target="_blank" rel="noreferrer">
                    1
                  </a>

                  <a href="https://archivo.lapatria.com/opinion/breves/protagonista/mediatecas-448539" className="hover:font-bold hover:text-blue-cide" title="La Patria: Mediatecas" target="_blank" rel="noreferrer">
                    2
                  </a>

                  <a
                    href="https://www.vozdeladorada.co/post/el-gobierno-de-caldas-entreg%C3%B3-la-%C3%BAltima-mediateca-construida-en-esta-administraci%C3%B3n"
                    className="hover:font-bold hover:text-blue-cide"
                    title="Voz de La Dorada: Mediatecas"
                    target="_blank"
                    rel="noreferrer"
                  >
                    3
                  </a>

                  <a href="https://www.eje21.com.co/2018/12/mediateca-de-anserma-beneficiara-a-33-mil-habitantes/" className="hover:font-bold hover:text-blue-cide" title="Eje 21: Mediatecas" target="_blank" rel="noreferrer">
                    4
                  </a>

                  <a href="https://mundonoti.wordpress.com/2019/06/14/en-viterbo-lo-hicimos-juntos/" className="hover:font-bold hover:text-blue-cide" title="Mundo Noticias: Mediatecas" target="_blank" rel="noreferrer">
                    5
                  </a>

                  <a
                    href="https://www.caldas.gov.co/index.php/tramitesyservicios/ayuda-en-linea/chat/135-destacado/7386-el-gobierno-de-caldas-entrego-la-ultima-mediateca-construida-en-esta-administracion-un-crecimiento-de-mas-del-50-de-las-visitas-registran-las-bibliotecas-publicas-despues-de-la-implementacion-de-estos-espacios-que-vinculan-la-tecnologia-y-la-lectura"
                    className="hover:font-bold hover:text-blue-cide"
                    title="Gobierno de Caldas: Mediatecas"
                    target="_blank"
                    rel="noreferrer"
                  >
                    6
                  </a>

                  <a
                    href="https://caldas.gov.co/index.php/prensa/noticias/6692-caldas-es-modelo-en-el-pais-en-la-implementacion-de-mediatecas-con-esta-estrategia-aumento-en-un-23-la-asistencia-de-ninos-y-jovenes-a-las-bibliotecas-publicas"
                    className="hover:font-bold hover:text-blue-cide"
                    title="Gobierno de Caldas: Mediatecas"
                    target="_blank"
                    rel="noreferrer"
                  >
                    7
                  </a>
                </div>
              </div>

              <p className="sm:text-right">
                <a href="/#formulario-contacto" className="underline hover:text-blue-cide">
                  Más información
                </a>
              </p>
            </div>

            <div className="flex justify-center">
              <video className="w-80 h-52 sm:w-128 sm:h-80 lg:w-160 lg:h-96" src={require("../assets/videos/mediateca.mp4")} poster={require("../assets/images/poster_mediateca.png")} controls></video>
            </div>
          </div>
        </AccordionBody>
      </Accordion>

      <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
        <AccordionHeader onClick={() => handleOpen(2)}>
          <div className="flex items-center space-x-5 h-20 sm:h-28 w-full">
            <div className="w-1/3 lg:w-1/5 h-full flex justify-center items-center">
              <img src={theme === "dark" ? require("../assets/images/dark/logo_lasevi_white.png") : require("../assets/images/logo_lasevi.png")} alt="LASEVI" className="w-3/5" />
            </div>
            <h1 className="text-black dark:text-white text-xl lg:text-3xl font-century-gothic">LASEVI</h1>
          </div>
        </AccordionHeader>
        <AccordionBody>
          <div className="font-century-gothic text-sm lg:text-lg text-black dark:text-white space-y-5">
            <p>
              Un <b className="font-bold">LASEVI</b> es un Laboratorio Interactivo de Seguridad Vial, donde niños, jóvenes, adultos y mayores pueden aprender de forma divertida y con estrategias diseñadas por Doctores en Educación sobre los temas
              relacionados con la Seguridad Vial, temas claves para prevenir muertes y lesiones graves por causa de accidentes de tránsito
            </p>

            <div className="flex sm:justify-between flex-col sm:flex-row text-xl gap-y-3">
              <div className="flex space-x-6">
                <p>
                  Medios&nbsp;&nbsp;<i className="fa-solid fa-book"></i>
                </p>
                <div className="space-x-5 animate-pulse">
                  <a href="https://www.youtube.com/watch?v=7V69KrtNrSg" className="hover:font-bold hover:text-blue-cide" title="Youtube: LASEVI" target="_blank" rel="noreferrer">
                    1
                  </a>
                  <a href="https://archivo.lapatria.com/publirreportajes/lasevi-un-laboratorio-que-salva-vidas-en-la-dorada-450460" className="hover:font-bold hover:text-blue-cide" title="La Patria: LASEVI" target="_blank" rel="noreferrer">
                    2
                  </a>
                  <a href="https://www.eje21.com.co/2020/02/lasevi-un-laboratorio-que-salva-vidas-en-la-dorada/" className="hover:font-bold hover:text-blue-cide" title="Eje 21: LASEVI" target="_blank" rel="noreferrer">
                    3
                  </a>
                </div>
              </div>

              <p className="sm:text-right">
                <a href="/#formulario-contacto" className="underline hover:text-blue-cide">
                  Más información
                </a>
              </p>
            </div>

            <div className="flex justify-center">
              <video className="w-80 h-52 sm:w-128 sm:h-80 lg:w-160 lg:h-96" src={require("../assets/videos/lasevi.mp4")} poster={require("../assets/images/poster_lasevi.png")} controls></video>
            </div>
          </div>
        </AccordionBody>
      </Accordion>

      <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
        <AccordionHeader onClick={() => handleOpen(3)}>
          <div className="flex items-center justify-start space-x-5 h-20 sm:h-28 w-full">
            <div className="w-1/3 lg:w-1/5 h-full flex justify-center items-center">
              <img src={theme === "dark" ? require("../assets/images/logo_funec.png") : require("../assets/images/logo_funec.png")} alt="FUNEC" className="w-3/5 h-auto" />
            </div>
            <h1 className="text-black dark:text-white text-xl lg:text-3xl font-century-gothic">FUNEC</h1>
          </div>
        </AccordionHeader>
        <AccordionBody>
          <div className="font-century-gothic text-sm lg:text-lg text-black dark:text-white space-y-5">
            <p>En CIDE orientamos cursos especializados como el “Diplomado en habilidades comunicacionales en proyectos, apropiación de las TIC y trabajo en equipo” para la Fundación Universitaria del Eje Cafetero FUNEC.</p>

            <div className="flex justify-center">
              <img src={require("../assets/images/funec_2.jpg")} alt="FUNEC" className="w-4/5 lg:w-1/2" />
            </div>
          </div>
        </AccordionBody>
      </Accordion>

      <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
        <AccordionHeader onClick={() => handleOpen(4)}>
          <div className="flex items-center justify-start space-x-5 h-20 sm:h-28 w-full">
            <div className="w-1/3 lg:w-1/5 h-full flex justify-center items-center">
              <img src={theme === "dark" ? require("../assets/images/logo_gobernacion_caldas.png") : require("../assets/images/logo_gobernacion_caldas.png")} alt="Gobernación de Caldas" className="h-5/6" />
            </div>
            <h1 className="text-black dark:text-white text-xl lg:text-3xl font-century-gothic">Gobernación de Caldas</h1>
          </div>
        </AccordionHeader>
        <AccordionBody>
          <div className="font-century-gothic text-sm lg:text-lg text-black dark:text-white space-y-5">
            <p>
              En CIDE creamos y ponemos en marcha estrategias de comunicación efectivas, a través de medios físicos y digitales, con impacto social, como la promoción de la erradicación del trabajo infantil en las minas del Departamento de Caldas
            </p>

            <div className="flex justify-center">
              <img src={require("../assets/images/gobernacion_1.jpg")} alt="Gobernación de Caldas" className="w-4/5 lg:w-1/2" />
            </div>

            <p>También ayudamos a formar personas en temas que mejoran la calidad de vida, como la capacitación en competencias laborales orientada a los mineros de los municipios La Merced, Palestina, San José y Filadelfia</p>

            <div className="flex justify-center">
              <img src={require("../assets/images/gobernacion_2.jpg")} alt="Gobernación de Caldas" className="w-4/5 lg:w-1/2" />
            </div>
          </div>
        </AccordionBody>
      </Accordion>

      <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
        <AccordionHeader onClick={() => handleOpen(5)}>
          <div className="flex items-center justify-start space-x-5 h-20 sm:h-28 w-full">
            <div className="w-1/3 lg:w-1/5 h-full flex justify-center items-center">
              <img src={theme === "dark" ? require("../assets/images/logo_alcaldia_manizales_white.png") : require("../assets/images/logo_alcaldia_manizales.png")} alt="Alcaldía de Manizales" className="w-3/5 h-auto" />
            </div>
            <h1 className="text-black dark:text-white text-xl lg:text-3xl font-century-gothic">Alcaldía de Manizales</h1>
          </div>
        </AccordionHeader>
        <AccordionBody>
          <div className="font-century-gothic text-sm lg:text-lg text-black dark:text-white space-y-5">
            <p>
              En CIDE apoyamos a las administraciones locales con sus procesos de transformación digital y su respectivo acercamiento a la comunidad, a través de proyectos especiales tales como la promoción y difusión de la estrategia de Gobierno en
              línea del Municipio de Manizales, mediante el cual facilitamos que la ciudadanía en general aprenda y cumpla con su obligación del impuesto predial usando la alternativa de pagos electrónicos, haciendo uso eficiente de su tiempo,
              desplazamiento y recursos
            </p>

            <div className="flex justify-center">
              <img src={require("../assets/images/alcaldia.jpg")} alt="Alcaldía de Manizales" className="w-4/5 lg:w-1/2" />
            </div>
          </div>
        </AccordionBody>
      </Accordion>
    </Fragment>
  );
}
