import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

const NavBar = ({ theme, setTheme }) => {
    const [navbar, setNavbar] = useState(false);

    const handleNavbar = () => {
        setNavbar(!navbar);

        if (navbar) {
            document.querySelector("nav").classList.remove("bg-black", "border-b", "border-white");
        } else {
            document.querySelector("nav").classList.add("bg-black", "border-b", "border-white")
        }
    };

    //close navbar when click a link

    const closeNavbar = () => {
        if (navbar) {
            setNavbar(false);
            document.querySelector("nav").classList.remove("bg-black", "border-b", "border-white");
        }
    };



    useEffect(() => {
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme]);

    const handleThemeSwitch = () => {
        setTimeout(() => {
            setTheme(theme === "dark" ? "light" : "dark");
        }, 0);
    };

    useEffect(() => {
        const nav = document.querySelector("nav");
        const handleScroll = () => {
            if (window.scrollY > 200) {
                nav.classList.add("bg-black");
            } else {
                if (navbar) {
                    nav.classList.add("bg-black");
                } else {
                    nav.classList.remove("bg-black");
                }
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [navbar]);


    useEffect(() => {
        const checkbox = document.querySelector("#checkbox");
        return checkbox.addEventListener("change", handleThemeSwitch);

    });

    return (
        <Fragment>
            <nav className="fixed text-white text-sm w-full z-50">
                <div className="justify-around container mx-auto gap-x-5 px-4 lg:items-center lg:flex lg:px-8">
                    <article>
                        <div className="flex items-center justify-between py-3 lg:py-5 lg:block">
                            <Link to="/" onClick={closeNavbar}>
                                <img src={require("../assets/images/logo_cide_white.png")} alt="Henry Ford" className="h-7" />
                            </Link>
                            <div className="lg:hidden">
                                <button className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border" onClick={handleNavbar}>
                                    {navbar ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>
                    </article>

                    <article className="lg:w-full">
                        <section className={`items-center lg:justify-between pb-7 shadow-2xl lg:shadow-none mt-3 lg:flex lg:pb-0 lg:mt-0 space-y-6 lg:space-y-0 ${navbar ? "block" : "hidden"}`}>
                            <ul className="lg:space-x-5 flex flex-col lg:flex-row gap-y-3">
                                <li>
                                    <NavLink to="/economia" activeclassname="active" className="nav-item" onClick={closeNavbar}>Economía</NavLink>
                                </li>

                                <li>
                                    <NavLink to="/educacion" activeclassname="active" className="nav-item" onClick={closeNavbar}>Educación</NavLink>
                                </li>

                                <li>
                                    <NavLink to="/salud" activeclassname="active" className="nav-item" onClick={closeNavbar}>Salud</NavLink>
                                </li>

                                <li>
                                    <NavLink to="/deporte" activeclassname="active" className="nav-item" onClick={closeNavbar}>Deporte</NavLink>
                                </li>

                                <li>
                                    <NavLink to="/medioambiente" activeclassname="active" className="nav-item" onClick={closeNavbar}>Medioambiente</NavLink>
                                </li>
                            </ul>

                            <div className="text-xs ml-3 lg:ml-0">
                                <input type="checkbox" className="opacity-0 absolute check-dark" id="checkbox" />
                                <label htmlFor="checkbox" className="label-dark" title="Modo Oscuro">
                                    <i className="fas fa-moon"></i>
                                    <i className="fas fa-sun"></i>
                                    <div className="ball" />
                                </label>
                            </div>
                        </section>
                    </article>


                </div>
            </nav>
        </Fragment>
    );
}

export default NavBar;