import React, { Fragment } from 'react';


const BannerHelp = () => {
   
    return (
        <Fragment>
            <div className="bg-people_hands bg-center bg-cover">

                <div className="bg-blue-600 bg-opacity-50 py-14 lg:py-28 flex justify-center">
                    <a href="/#formulario-contacto">
                        <button className="p-5 lg:py-8 lg:px-16 border-2 border-white text-white font-bold text-lg lg:text-4xl hover:bg-blue-cide">
                            ¿Podemos ayudarte?
                        </button>
                    </a>
                </div>
            </div>
        </Fragment>

    );
}

export default BannerHelp;
