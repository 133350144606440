import React, { Fragment } from 'react';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards } from "swiper";


const SocialImpact = ({ theme }) => {

    return (
        <Fragment>
            <section className="flex flex-col lg:flex-row overflow-hidden gap-y-4">
                <div className="flex justify-center items-center w-full lg:w-1/3">
                    <h1 className="font-black dark:text-white text-xl lg:text-4xl text-center">Impacto<br /> Social</h1>
                </div>

                <div className="flex justify-center items-center w-full lg:w-2/3 relative pt-5 dark:text-white">
                    <Swiper
                        effect={"cards"}
                        grabCursor={true}
                        modules={[EffectCards]}
                        className="mySwipe"
                    >
                        <SwiperSlide className="flex flex-col p-4 lg:p-10 text-center">
                            <p>
                                <b>+65</b> empresas del <b>sector privado</b> fueron
                                apoyadas en procesos de desarrollo y
                                transformación digital
                            </p>
                            <br />
                            <p>
                                <b>+24</b> empresas del <b>sector público</b> han impactado
                                sus comunidades urbanas y rurales con nuestros
                                proyectos de CTeI
                            </p>
                        </SwiperSlide>
                        <SwiperSlide className="flex flex-col p-4 lg:p-10 text-center">
                            <p>
                                <b>+1.872</b> personas han recibido <b>formación</b>
                            </p>
                            <br />
                            <p>
                                <b>+1.489</b> niños han tenido acceso a nuevos <b>títulos
                                    bibliográficos</b> incrementando indicadores de
                                lectura a nivel municipal
                            </p>


                        </SwiperSlide>
                        <SwiperSlide className="flex flex-col p-4 lg:p-10 text-center">
                            <p>
                                <b>+135</b> padres de familia reconocen la importancia
                                del <b>deporte</b> en la formación de sus hijos menores
                                de 15 años
                            </p>
                        </SwiperSlide>




                    </Swiper>
                    <img src={(theme === 'dark') ? require('../assets/images/logo_cide_white.png') : require('../assets/images/logo_cide_black.png')} alt="impacto-social" className="absolute left-1/4 top-0 h-7 lg:h-14 object-cover z-10" />
                </div>

            </section>


        </Fragment>


    )
}

export default SocialImpact;