import React, { Fragment } from "react";

/* import casual_meeting from '../assets/images/casual_meeting.jpg'; */

const Economy = ({ theme }) => {
  return (
    <Fragment>
      <section className="h-screen bg-center bg-cover bg-no-repeat bg-agricultural_building text-white">
        <div className="h-full py-12 flex items-center pl-20 bg-gradient-to-r from-black">
          <p className="text-3xl lg:text-7xl font-bold">
            Ciencia, Tecnología <br />e Innovación que <br />
            genera Bienestar Social
          </p>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-dark dark:text-white py-8 lg:py-14">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-5">
          <div className="flex flex-col lg:w-1/5 justify-center items-center">
            <div className="h-20 w-20 rounded-full overflow-hidden">
              <img src={require("../assets/images/henry_ford.jpg")} alt="Henry Ford" className="h-full w-full" />
            </div>
            <p className="font-bold">Henry Ford</p>
            <p>Empresario</p>
          </div>
          <p className="text-sm lg:text-3xl font-bold lg:w-2/5 text-center">
            "Un negocio que no hace nada más <br />
            que dinero, es un negocio pobre"
          </p>
          <div className="h-16 lg:h-32 lg:w-1/5">
            <img src={theme === "dark" ? require("../assets/images/dark/firma_henry_ford_white.png") : require("../assets/images/firma_henry_ford.png")} alt="Firma Henry Ford" className="h-full w-full" />
          </div>
        </div>
      </section>

      <section>
        <div className="bg-white dark:bg-semi-dark dark:text-white flex justify-center p-8 lg:py-14">
          <p className="w-full lg:w-3/5 text-sm lg:text-3xl text-center">
            El tejido empresarial es uno de los pilares fundamentales para generar bienestar social. El empleo que se origina desde las empresas es la fuente de ingresos y sustento para millones de colombianos, por esto, en CIDE nos concentramos en
            crear soluciones tecnológicas e innovadoras que les permita a compañías de todos los sectores mejorar sus condiciones de calidad, competitividad y eficiencia, aplicando diversas estrategias de transformación digital.
          </p>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-semi-dark dark:text-white flex justify-center p-8 lg:py-14">
        <div className="container mx-auto flex flex-col gap-4 lg:flex-row relative">
          <div className="w-full h-full flex justify-center lg:justify-end">
            <img src={require("../assets/images/registra_1.jpg")} alt="Datarenta" className="w-4/5 lg:w-1/2 rounded-xl overflow-hidden shadow-xl" />
          </div>

          <div className="w-full lg:w-3/5 lg:absolute left-0 flex flex-col items-center lg:h-full gap-5 justify-center">
            <div className=" w-full flex justify-center lg:justify-start">
              <img src={theme === "dark" ? require("../assets/images/dark/logo_registra_white.png") : require("../assets/images/logo_registra.png")} alt="Logo Datarenta" className="h-14 lg:h-20" />
            </div>

            <div>
              <div className="mb-5 p-4 lg:p-10 bg-[#9eadd8] bg-opacity-90 rounded-xl text-sm lg:text-2xl font-semibold">
                <p>Con Registra® su empresa cumplirá de manera fácil, rápida y segura los requisitos que exige el Régimen General de Protección de Datos Personales en Colombia - Ley 1581 de 2012</p>
                <p className="text-right">
                  <a href="/#formulario-contacto" className="underline">
                    Más información
                  </a>
                </p>
              </div>

              <div>
                <div className="flex space-x-6 justify-center">
                  <p>
                    Medios&nbsp;&nbsp;<i className="fa-solid fa-book"></i>
                  </p>
                  <div className="space-x-5 animate-pulse">
                    <a href="https://archivo.lapatria.com/publirreportajes/la-sic-ha-multado-empresas-del-sector-salud-427629" className="hover:font-bold hover:text-blue-cide" title="La Patria: Registra" target="_blank" rel="noreferrer">
                      1
                    </a>
                    <a
                      href="https://archivo.lapatria.com/publirreportajes/proteccion-de-datos-personales-5-claves-para-una-solucion-efectiva-423192"
                      className="hover:font-bold hover:text-blue-cide"
                      title="La Patria: Registra"
                      target="_blank"
                      rel="noreferrer"
                    >
                      2
                    </a>
                    <a href="https://archivo.lapatria.com/publirreportajes/mis-bases-de-datos-son-domesticas-o-comerciales-425291" className="hover:font-bold hover:text-blue-cide" title="La Patria: Registra" target="_blank" rel="noreferrer">
                      3
                    </a>
                    <a href="https://archivo.lapatria.com/publirreportajes/datos-sensibles-en-instituciones-educativas-427140" className="hover:font-bold hover:text-blue-cide" title="La Patria: Registra" target="_blank" rel="noreferrer">
                      4
                    </a>
                    <a href="https://archivo.lapatria.com/publirreportajes/con-registra-cero-multas-423992" className="hover:font-bold hover:text-blue-cide" title="La Patria: Registra" target="_blank" rel="noreferrer">
                      5
                    </a>
                    <a href="https://archivo.lapatria.com/sociales/seminario-16" className="hover:font-bold hover:text-blue-cide" title="La Patria: Registra" target="_blank" rel="noreferrer">
                      6
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-dark dark:text-white flex justify-center p-8 lg:py-14">
        <div className="container mx-auto flex flex-col gap-4 lg:flex-row relative">
          <div className="w-full lg:w-1/2 left-0 flex justify-center">
            <img src={require("../assets/images/cadimun_1.jpg")} alt="Cadimun" className="w-4/5 lg:w-full h-full rounded-xl overflow-hidden shadow-xl" />
          </div>

          <div className="w-full lg:w-3/5 lg:absolute right-0 flex flex-col items-center lg:h-full gap-5 justify-center">
            <div className=" w-full flex justify-center lg:justify-end">
              <img src={theme === "dark" ? require("../assets/images/dark/logo_cadimun_white.png") : require("../assets/images/logo_cadimun.png")} alt="Logo Cadimun" className="h-16 lg:h-28" />
            </div>
            <div className="p-4 lg:p-10 bg-[#9eadd8] bg-opacity-90 rounded-xl text-sm lg:text-2xl font-semibold">
              <p>Con nuestro proyecto CADIMUN Canasta Digital Municipal, los municipios pueden caracterizar sus pequeños productores, así como comercializar sus productos mediante un Centro de Acopio Digital</p>
              <p className="text-right">
                <a href="/#formulario-contacto" className="underline">
                  Más información
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-semi-dark dark:text-white flex justify-center p-8 lg:py-14">
        <div className="container mx-auto flex flex-col gap-4 lg:flex-row relative">
          <div className="w-full h-full flex justify-center lg:justify-end">
            <img src={require("../assets/images/datarenta_1.jpg")} alt="Datarenta" className="w-4/5 lg:w-1/2 rounded-xl overflow-hidden shadow-xl" />
          </div>

          <div className="w-full lg:w-3/5 lg:absolute left-0 flex flex-col items-center lg:h-full gap-5 justify-center">
            <div className=" w-full flex justify-center lg:justify-start">
              <img src={require("../assets/images/logo_datarenta.png")} alt="Logo Datarenta" className="h-16 lg:h-24" />
            </div>

            <div className="relative">
              <div className="mb-5 p-4 lg:p-10 bg-[#9eadd8] bg-opacity-90 rounded-xl text-sm lg:text-2xl font-semibold">
                <p>Nos encontramos trabajando en una herramienta digital que revolucionará la forma de realizar negocios entre propietarios de bienes raíces e inquilinos</p>
              </div>
              <div className="absolute bottom-0 flex justify-center w-full">
                <a href="/">
                  <button className="bg-blue-cide hover:bg-blue-700 rounded-full py-2 lg:py-3 px-7 text-white font-semibold text-sm lg:text-2xl">Quiero apoyar el proyecto</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-dark dark:text-white flex justify-center p-8 lg:py-14">
        <div className="container mx-auto flex flex-col gap-4 lg:flex-row relative">
          <div className="w-full lg:w-1/2 left-0 flex justify-center">
            <img src={require("../assets/images/people_contact_1.jpg")} alt="People contact" className="w-4/5 lg:w-full h-full rounded-xl overflow-hidden shadow-xl" />
          </div>

          <div className="w-full lg:w-3/5 lg:absolute right-0 flex flex-col items-center lg:h-full gap-5 justify-center">
            <div className=" w-full flex justify-center lg:justify-end">
              <img src={require("../assets/images/logo_people_contact.png")} alt="Logo People contact" className="h-16 lg:h-28" />
            </div>
            <div className="p-4 lg:p-10 bg-[#9eadd8] bg-opacity-90 rounded-xl text-sm lg:text-2xl font-semibold">
              <p>Servicio de integración tecnológica de módulos complementarios para la plataforma del sistema de información del área de Gestión de Talento Humano</p>
              <p className="text-right">
                <a href="/#formulario-contacto" className="underline">
                  Más información
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-semi-dark dark:text-white flex justify-center p-8 lg:py-14">
        <div className="container mx-auto flex flex-col gap-4 lg:flex-row relative">
          <div className="w-full h-full flex justify-center lg:justify-end">
            <img src={require("../assets/images/funec_1.jpg")} alt="Funec" className="w-4/5 lg:w-1/2 rounded-xl overflow-hidden shadow-xl" />
          </div>

          <div className="w-full lg:w-3/5 lg:absolute left-0 flex flex-col items-center lg:h-full gap-5 justify-center">
            <div className=" w-full flex justify-center lg:justify-start">
              <img src={require("../assets/images/logo_funec.png")} alt="Logo Funec" className="h-20 lg:h-24" />
            </div>

            <div className="mb-5 p-4 lg:p-10 bg-[#9eadd8] bg-opacity-90 rounded-xl text-sm lg:text-2xl font-semibold">
              <p>Desarrollo de la plataforma Research Expert para el apoyo a la aplicación de instrumentos de investigación</p>
              <p className="text-right">
                <a href="/#formulario-contacto" className="underline">
                  Más información
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-dark dark:text-white flex justify-center p-8 lg:py-14">
        <div className="container mx-auto flex flex-col gap-4 lg:flex-row relative">
          <div className="w-full lg:w-1/2 left-0 flex justify-center">
            <img src={require("../assets/images/interoperabilidad_1.jpg")} alt="People contact" className="w-4/5 lg:w-full h-full rounded-xl overflow-hidden shadow-xl" />
          </div>

          <div className="w-full lg:w-3/5 lg:absolute right-0 flex flex-col items-center lg:h-full gap-5 justify-center">
            <div className=" w-full flex justify-center lg:justify-end">
              <img src={theme === "dark" ? require("../assets/images/logo_alcaldia_manizales_white.png") : require("../assets/images/logo_alcaldia_manizales.png")} alt="Logo Alcaldía de Manizales" className="h-20 lg:h-28" />
            </div>
            <div className="p-4 lg:p-10 bg-[#9eadd8] bg-opacity-90 rounded-xl text-sm lg:text-2xl font-semibold">
              <p>Implementación de procesos de interoperabilidad para Gobierno en línea</p>
              <p className="text-right">
                <a href="/#formulario-contacto" className="underline">
                  Más información
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Economy;
