import React, { Fragment } from "react";

import BannerHelp from "../components/BannerHelp";

const Environment = ({ theme }) => {
  return (
    <Fragment>
      <section className="h-screen bg-center bg-cover bg-no-repeat bg-environment_image text-white">
        <div className="h-full py-12 flex items-center pl-20 bg-gradient-to-r from-black">
          <p className="text-3xl lg:text-7xl font-bold">
            Ciencia, Tecnología <br />e Innovación que <br />
            genera Bienestar Social
          </p>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-semi-dark dark:text-white py-8 lg:py-14">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-5">
          <div className="flex flex-col lg:w-1/5 justify-center items-center">
            <div className="h-20 w-20 rounded-full overflow-hidden">
              <img src={require("../assets/images/leonardo_dicaprio.jpg")} alt="Mahatma Gandhi" className="h-full w-full" />
            </div>
            <p className="font-bold">Leonardo DiCaprio</p>
            <p className="text-center">Actor y Ambientalista</p>
          </div>
          <p className="text-sm lg:text-3xl font-bold lg:w-2/5 text-center px-5">"El cambio climático es real y está ocurriendo ahora mismo"</p>
          <div className="h-14 lg:h-24 lg:w-1/5 flex justify-center">
            <img src={theme === "dark" ? require("../assets/images/dark/firma_leonardo_white.png") : require("../assets/images/firma_leonardo.png")} alt="Firma Henry Ford" className="h-full w-full lg:w-2/3" />
          </div>
        </div>
      </section>

      <section>
        <div className="bg-white dark:bg-dark dark:text-white flex justify-center p-8 lg:py-14">
          <p className="w-full lg:w-3/5 text-sm lg:text-3xl text-center">
            De una u otra forma y sólo por el hecho de existir, afectamos de múltiples formas nuestro hogar, el planeta Tierra. Tener conciencia de ello y comprender que nuestro paso por este mundo es temporal, nos debe alentar a realizar acciones
            que nos permita aportar en su conservación y sostenibilidad futura. En CIDE propendemos por la diseminación de esa conciencia y nos vinculamos en proyectos que ayuden a proteger los ecosistemas ambientales
          </p>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-semi-dark dark:text-white flex justify-center p-8 lg:py-14">
        <div className="container mx-auto flex flex-col gap-4 lg:flex-row relative">
          <div className="w-full h-full flex justify-center lg:justify-end">
            <img src={require("../assets/images/huella_ambiental_1.jpg")} alt="Datarenta" className="w-4/5 lg:w-1/2 rounded-xl overflow-hidden shadow-xl" />
          </div>

          <div className="w-full lg:w-3/5 lg:absolute left-0 flex flex-col items-center lg:h-full gap-5 justify-center">
            <div className=" w-full flex justify-center lg:justify-start">
              <img src={require("../assets/images/logo_huella_ambiental.png")} alt="Logo Huella Ambiental" className="h-14 lg:h-20" />
            </div>

            <div className="relative">
              <div className="mb-5 p-4 lg:p-10 bg-[#9eadd8] bg-opacity-90 rounded-xl text-sm lg:text-2xl font-semibold">
                <p>Nos encontramos trabajando en una herramienta para fortalecer el medio ambiente, disminuyendo el impacto de la Huella de Carbono</p>
              </div>
              <div className="absolute bottom-0 flex justify-center w-full">
                <a href="/#formulario-contacto">
                  <button className="bg-blue-cide hover:bg-blue-700 rounded-full py-2 lg:py-3 px-7 text-white font-semibold text-sm lg:text-2xl">Quiero apoyar el proyecto</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <BannerHelp />
      </section>
    </Fragment>
  );
};

export default Environment;
