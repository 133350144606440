import React, { Fragment } from 'react';

import Form from '../components/Form';
import SocialImpact from '../components/SocialImpact';
import SwiperCompanies from '../components/SwiperCompanies';
import BannerHelp from '../components/BannerHelp';


const Home = ({ theme }) => {

    function scrollToContact() {
        const element = document.getElementById('formulario-contacto');
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });


    }

    if (window.location.hash === '#formulario-contacto') {
        setTimeout(() => {
            scrollToContact();
        }, 600);
    }

    return (
        <Fragment>
            <section className="h-screen bg-center bg-cover bg-no-repeat bg-casual_meeting text-white">
                <div className="h-full py-12 flex items-center pl-20 bg-gradient-to-r from-black">
                    <p className="text-3xl lg:text-7xl font-bold">Ciencia, Tecnología <br />e Innovación que <br />genera Bienestar Social</p>
                </div>
            </section>

            <section className="flex">
                <div className="hidden lg:flex w-1/3 bg-contact bg-cover bg-center">

                    <div className="bg-blue-700 bg-opacity-50 w-full h-full flex flex-col items-end justify-end pb-10 pr-10">

                        <div className="text-white text-2xl  w-full h-full flex items-center pb-32 justify-end">
                            <div className="rotate-[-90deg] origin-bottom-right flex space-x-2">
                                <div>
                                    <div className="border-b-2 border-white px-20 h-5"> </div>
                                </div>
                                <p className="whitespace-nowrap">Estamos pora ti</p>
                            </div>
                        </div>

                        <div className="flex space-x-6 text-3xl text-white">
                            <p><a href="https://www.facebook.com/registracide" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook hover:text-gray-300"></i></a></p>
                            <p><a href="https://co.linkedin.com/company/registra%C2%AE" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin hover:text-gray-300x   "></i></a></p>
                            <p><a href="https://twitter.com/Registracide" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter hover:text-gray-300x  "></i></a></p>
                            <p><a href="https://www.instagram.com/registracide/" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram hover:text-gray-300x "></i></a></p>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-2/3 pt-16 p-7 lg:p-32 xl:px-52 dark:bg-dark" id="formulario-contacto" name="formulario-contacto">
                    <Form />
                </div>
            </section>

            <section className="bg-gray-50 dark:bg-semi-dark w-full py-7 lg:py-14">
                <SocialImpact theme={theme} />
            </section>

            <section className="bg-white dark:bg-dark py-7 lg:py-14 ">
                <h1 className="text-center dark:text-white text-xl lg:text-4xl font-bold px-6">Ejecutamos Proyectos de CTeI en todo Colombia</h1>
                <div className="flex justify-center mt-7">
                    <img src={(theme === 'dark') ? require('../assets/images/dark/mapa_proyectos_white.png') : require('../assets/images/mapa_proyectos.png')} alt="mapa de proyectos de CIDE" className="w-4/5 sm:w-3/5 h-auto" />
                </div>
            </section>

            <section>
                <BannerHelp />
            </section>

            <section className="bg-white dark:bg-dark py-7 lg:py-14 px-8 lg:px-20">
                <div className="space-y-10">
                    <h1 className="text-center dark:text-white text-lg sm:text-2xl lg:text-4xl font-bold px-6">¡En CIDE cumplimos nuestros compromisos!</h1>
                    <p className="text-center dark:text-white">Algunas empresas que han confiado la ejecución de proyectos con CIDE son:</p>
                    <div className="flex justify-center items-center">
                        <SwiperCompanies />
                    </div>

                    <div className="flex justify-center">
                        <button onClick={scrollToContact} className="px-10 py-5 bg-blue-cide rounded-full text-white font-bold hover:bg-blue-700">¿Podemos ayudarte?</button>
                    </div>
                </div>
            </section>

            <section className="bg-gray-50 dark:bg-semi-dark py-7 lg:py-14 px-8 lg:px-20">
                <div className="space-y-10">
                    <h1 className="text-center dark:text-white text-lg sm:text-2xl lg:text-4xl font-bold px-6">CIDE en Acción</h1>
                    <p className="text-center dark:text-white">Algunas de nuestras marcas insignias que dan cuenta de la ejecución de proyectos y soluciones integrales son:</p>
                    <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-y-5 justify-center items-center container mx-auto">
                        <div className="flex justify-center items-center">
                            <img src={(theme === 'dark' ? require('../assets/images/dark/logo_softgreen_white.png') : require('../assets/images/logo_softgreen.png'))} alt="Softgreen" className="h-16" />
                        </div>

                        <div className="flex justify-center items-center">
                            <img src={(theme === 'dark' ? require('../assets/images/dark/logo_lasevi_white.png') : require('../assets/images/logo_lasevi.png'))} alt="LASEVI" className="h-16" />
                        </div>

                        <div className="flex justify-center items-center">
                            <img src={require('../assets/images/logo_datarenta.png')} alt="Datarenta" className="h-14" />
                        </div>

                        <div className="flex justify-center items-center">
                            <img src={(theme === 'dark' ? require('../assets/images/dark/logo_cadimun_white.png') : require('../assets/images/logo_cadimun.png'))} alt="Cadimun" className="h-20" />
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <button onClick={scrollToContact} className="px-10 py-5 bg-blue-cide rounded-full text-white font-bold hover:bg-blue-700">¿Podemos ayudarte?</button>
                    </div>
                </div>
            </section>

            <section className="bg-white dark:bg-dark py-7 lg:py-14 px-8 lg:px-20">
                <div className="space-y-10">
                    <h1 className="text-center dark:text-white text-lg sm:text-2xl lg:text-4xl font-bold px-6">Talento CIDE</h1>
                    <div className="flex justify-center">
                        <p className="text-center dark:text-white lg:w-1/2">
                            En CIDE contamos con un selecto grupo de profesionales apasionados por la tecnología y el
                            deseo de servir a la sociedad, siempre dispuestos a entregar lo mejor de sus conocimientos y
                            habilidades en pro de alcanzar las metas propuestas en cada reto
                        </p>
                    </div>
                    <div className="flex justify-center items-center">
                        <div className="relative w-4/5 sm:w-3/5 h-auto">
                            <img src={require('../assets/images/working_team.jpg')} alt="talento cide" />
                            <div className="w-full h-full top-0 bg-blue-cide opacity-50 absolute"></div>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <p className="text-center dark:text-white lg:w-1/2">
                            Además de nuestro equipo de base, CIDE cuenta con una red de aliados nacionales e
                            internacionales, profesores, investigadores, doctores, magísteres, especialistas que intervienen de
                            manera armónica y proactiva de acuerdo a las habilidades requeridas en cada tipo de
                            proyecto, en el momento oportuno, gracias a nuestra experimentada metodología en Gestión
                            de Proyectos que se basa en los estándares definidos por el Project Management Institute PMI
                        </p>
                    </div>
                    <div className="flex justify-center">
                        <button onClick={scrollToContact} className="px-10 py-5 bg-blue-cide rounded-full text-white font-bold hover:bg-blue-700">¿Podemos ayudarte?</button>
                    </div>
                </div>
            </section>

        </Fragment>


    )
}

export default Home;