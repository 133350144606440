import React from "react";

function Icon() {
    return (
        <i className="fa-solid fa-cloud-arrow-down text-blue-cide text-xl hover:text-blue-500 text-center"></i>
    );
}

const TableRow = ({ title, link, fileName }) => {
    return (
        <tr className="border-b">
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-center">{title}</td>
            <td className="text-sm font-light px-6 py-4 whitespace-nowrap text-center">
                <a href={link} download={fileName}>
                    <Icon />
                </a>
            </td>
        </tr>
    );
};

export default TableRow;
