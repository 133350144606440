import React, { Fragment } from "react";

const UseTerms = () => {
  return (
    <Fragment>
      <section className="bg-center bg-cover bg-no-repeat bg-useterms_image text-white">
        <div className="h-full py-28 sm:py-32 lg:py-52 2xl:py-72 flex items-center pl-20 bg-gradient-to-r from-black">
          <p className="text-3xl lg:text-7xl font-bold">Términos de uso</p>
        </div>
      </section>

      <section className="dark:bg-dark dark:text-white py-7 lg:py-14">
        <div className="container mx-auto text-justify">
          Estos son los términos y condiciones de uso del sitio web oficial del <span className="font-bold">CENTRO DE INTEGRACIÓN Y DESARROLLO EMPRESARIAL</span>, en adelante <span className="font-bold">CIDE</span>. El{" "}
          <span className="font-bold">CIDE</span> es una Entidad Sin Ánimo de Lucro (ESAL), de naturaleza privada, identificada con NIT 810.005.090-4, con sede principal en la ciudad de Manizales (Colombia), cuyo objetivo principal es propender por
          el bienestar de los colombianos a través de la ejecución de proyectos basados en Ciencia, Tecnología e Innovación (CTeI) mediante los cuales se apoyan cinco áreas claves: desarrollo económico (empresas), educación, salud, deporte y medio
          ambiente.
          <br />
          <br />
          En el sitio web del <span className="font-bold">CIDE</span> se publica información general de la organización, proyectos que hemos desarrollado con instituciones que han confiado en la experiencia y calidad que de nuestra entidad, proyectos
          que se encuentran en operación, así como proyectos que se encuentran en proceso de gestación y en los cuales puedes{" "}
          <a href="/#formulario-contacto" className="text-blue-cide font-bold hover:text-blue-500">
            participar
          </a>{" "}
          de diferentes formas. Finalmente, nuestro sitio web establece un canal de comunicación directo, donde puedes dejar tu información básica de contacto y uno de nuestros colaboradores se comunicará contigo en el menor tiempo posible.
          <br />
          <br />
          <h1 className="font-bold">1. Aceptación</h1>
          <br />
          Es responsabilidad de todo usuario visitante leer los presentes términos y condiciones de uso, por tanto, al acceder al sitio web del <span className="font-bold">CIDE</span>, el usuario visitante acepta de manera incondicional y plena los
          términos y condiciones proferidos en el presente documento. Por lo anterior, se recomienda la visita periódica a la sección de términos y condiciones.
          <br />
          <br />
          El usuario visitante será responsable por cualquier uso indebido, anormal o ilícito que se haga del sitio web y sus contenidos. En especial, el usuario se compromete a:
          <ol className="list-inside ml-10">
            <li>a) No descargar las imágenes, textos y demás contenido publicado en el sitio web, a menos que expresamente se mencione lo contrario.</li>
            <li>
              b) No utilizar contenidos (imágenes, textos, vídeos) para publicidad sin previa autorización del <span className="font-bold">CIDE</span>.
            </li>
            <li>
              c) No realizar actividades técnicas que impidan la libre administración, uso y publicación de los contenidos por parte de los administradores debidamente autorizados por el <span className="font-bold">CIDE</span>.
            </li>
            <li>
              d) No incurrir en conductas ilícitas como daños o ataques informáticos, interceptación de comunicaciones, infracciones al derecho de autor, uso no autorizado de terminales, usurpación de identidad, revelación de secretos o falsedad en
              documentos públicos o privados, adulteración o modificación de contenidos, tomando como base cualquier publicación que se efectúe en el sitio web del <span className="font-bold">CIDE</span>.
            </li>
            <li>e) No publicar contenido pornográfico, injurioso, falso, y en general cualquier contenido que atente contra la seguridad, dignidad e intimidad de terceros.</li>
            <li>
              f) No alterar el uso de las marcas, logos y demás signos distintivos, tanto del <span className="font-bold">CIDE</span> como de terceros que aparezcan en cualquier publicación que se realice en el sitio web, incluyendo aquellas que por
              actividades de mantenimiento y/o actualización, sean retiradas del sitio web.
            </li>

            <li>h) Eludir o intentar eludir las medidas de seguridad adoptadas en este sitio web.</li>
          </ol>
          <br />
          En todo caso, el usuario visitante entiende y acepta que el <span className="font-bold">CIDE</span> se reserva el derecho de tomar acciones de tipo administrativo y legal contra cualquier usuario visitante que, a juicio del{" "}
          <span className="font-bold">CIDE</span>, haga uso indebido de este sitio web. Lo anterior implica que el usuario visitante se hace responsable de indemnizar cualquier daño material e inmaterial causado tanto al{" "}
          <span className="font-bold">CIDE</span> como a terceros cuyos derechos resulten vulnerados por su actuación.
          <br />
          <br />
          <h1 className="font-bold">2. Responsabilidad</h1>
          <br />
          El usuario visitante entiende y acepta que el <span className="font-bold">CIDE no se compromete</span> o se hace responsable por comentarios y opiniones publicadas por terceros en los diferentes espacios abiertos para este fin, tanto en el
          sitio web como en sus redes sociales oficiales conexas.
          <br />
          <br />
          El <span className="font-bold">CIDE</span> realiza esfuerzos administrativos, técnicos y tecnológicos para mantener su sitio web libre de virus y software malicioso, sin embargo, el usuario visitante acepta que de ninguna forma el{" "}
          <span className="font-bold">CIDE</span> garantiza la ausencia de elementos que puedan producir alteraciones o daños en sus sistemas informáticos, por lo que no se hace responsable por las referidas alteraciones.
          <br />
          <br />
          Este sitio web <span className="font-bold">no efectúa</span> transacciones comerciales, cotizaciones en línea, pagos electrónicos, y en general, no efectúa actividades de comercio electrónico, por tanto, el usuario visitante entiende que
          para efectuar cualquier actividad de tipo comercial o bancario con <span className="font-bold">CIDE</span> debe comunicarse a través del canal de{" "}
          <a href="/#formulario-contacto" className="font-bold text-blue-cide hover:text-blue-500">
            contacto
          </a>
          , donde se le explicará los procesos y procedimientos que usa nuestra organización para estos fines.
          <br />
          <br />
          En este sitio web el <span className="font-bold">CIDE</span> no se compromete a publicar información en tiempo real o de base para la ejecución de transacciones comerciales, por tanto, no es responsable de ninguna forma por daños o
          afectaciones que puedan generarse debido a datos erróneos o no publicados.
          <br />
          <br />
          <h1 className="font-bold">3. Sitios Web de terceros</h1>
          <br />
          Dentro de los contenidos publicados en el sitio web del <span className="font-bold">CIDE</span>, el usuario visitante puede encontrar hipervínculos que le permiten el acceso a otras páginas o sitios web de terceros, estos enlaces se han
          dispuesto con el objetivo de informar sobre la existencia de otras fuentes que le permitan ampliar la información que se publica. Sin perjuicio de lo anterior, el usuario visitante acepta que el <span className="font-bold">CIDE</span> no se
          hace responsable por el contenido, funcionamiento, accesibilidad, gestión de la información, y/o de la privacidad que puedan realizar los operadores o administradores de los sitios web cuya propiedad sea de terceros, por tanto, el usuario
          visitante entiende que al hacer clic en dichos enlaces y salir del sitio web del <span className="font-bold">CIDE</span>, lo hace bajo su propio riesgo y responsabilidad.
          <br />
          <br />
          <h1 className="font-bold">4. Propiedad Industrial e Intelectual</h1>
          <br />
          El sitio web del <span className="font-bold">CIDE</span>{" "}
          <a href="/" className="font-bold hover:text-blue-cide">
            (www.cide.org.co)
          </a>
          , su diseño, los contenidos de todo tipo: bases de datos, repositorios, interfaces, herramientas, apps, software, libros, artículos, vídeos, documentos digitales y demás materiales que se hayan publicado, se encuentren publicados a la
          fecha, o se publiquen en el futuro; se encuentran protegidos por las normas nacionales e internacionales vigentes relacionadas con Propiedad Industrial y Propiedad Intelectual, por tanto, su uso, reproducción, comunicación pública, puesta a
          disposición, difusión, transformación, distribución, alquiler, préstamo, venta, total o parcial, en todo o en parte, en formato impreso, digital y en general por cualquier medio conocido o por conocer en el estado de la técnica, se
          encuentran prohibidos, y solo serán lícitos en la medida en que se cuente con la autorización previa y expresa por escrito del <span className="font-bold">CIDE</span>.
          <br />
          <br />
          Las limitaciones y excepciones al Derecho de Autor solo serán aplicables en la medida en que se den dentro de los denominados “Usos Honrados”; es decir, siempre y cuando estén previa y expresamente establecidas en disposición legal; no
          causen un grave e injustificado perjuicio a los intereses legítimos del autor o titular; y no atenten contra la normal explotación de la obra. En todo caso, cualquier uso, remisión o utilización mediante vínculos o links a los contenidos
          publicados en el sitio web del <span className="font-bold">CIDE</span>, deberán darse los créditos respectivos institucionales, y respetarse la normativa vigente y aplicable sobre Derechos Morales.
          <br />
          <br />
          Las marcas, logos, nombre, enseñas, lemas y demás signos distintivos relacionados con el <span className="font-bold">CIDE</span>, sus productos y/o servicios, son de titularidad del <span className="font-bold">CIDE</span>, en consecuencia,
          está prohibida su reproducción total o parcial, con independencia de que se trate de usos comerciales o no. Si desea hacer uso de cualquiera de estos signos distintivos o de los contenidos publicados a través de este sitio web, debe
          solicitar la correspondiente autorización previa y expresa del <span className="font-bold">CIDE</span>, esto lo puede realizar a través de nuestro canal de{" "}
          <a href="/#formulario-contacto" className="font-bold text-blue-cide hover:text-blue-500">
            contacto virtual
          </a>
          .
          <br />
          <br />
          Varias de las imágenes, íconos, vídeos publicados en el sitio web del <span className="font-bold">CIDE</span> y/o a través de sus redes sociales oficiales conexas, son de tipo comercial, se usan con el propósito de facilitar la comprensión
          de los textos expuestos, y son tomadas de bancos de imágenes cuyo licenciamiento ha sido adquirido por nuestra organización. Es de aclarar, que <span className="font-bold">CIDE</span> no autoriza al usuario visitante a descargar,
          comercializar, alquilar, usar, manipular, modificar este tipo de material, que también se encuentra protegido por las leyes de derechos de autor.
          <br />
          <br />
          <h1 className="font-bold">5. Ley aplicable y resolución de controversias</h1>
          <br />
          Cualquier conflicto generado por la interpretación de los presentes términos y condiciones de uso de este sitio web, será resuelto bajo lo establecido en la normativa interna del <span className="font-bold">CIDE</span>, y en los aspectos
          que no estén expresamente regulados en ellas, por lo establecido en las leyes de la República de Colombia.
          <br />
          <br />
          Cualquier conflicto o controversia generado por el cumplimiento de estos términos y condiciones de uso de este sitio web, se resolverá en primer lugar mediante arreglo directo entre las partes, transacción directa o conciliación y según con
          los procedimientos legales establecidos en las leyes de la República de Colombia.
          <br />
          <br />
          Es posible que este sitio web se encuentre disponible en diferentes idiomas para facilitar el acceso a usuarios visitantes de otros países. Cualquier conflicto generado por la interpretación o traducción de los presentes términos y
          condiciones de uso, será resuelto única y exclusivamente con lo previsto en lo publicado en idioma Español.
          <br />
          <br />
          <h1 className="font-bold">6. Actualizaciones y modificaciones</h1>
          <br />
          Los presentes términos y condiciones de uso rigen para todos los servicios y comunicaciones, actuales o futuros, y abarcan a todos aquellos usuarios o terceros que accedan, permanezcan o hagan uso de este sitio web.
          <br />
          <br />
          En todo caso, el <span className="font-bold">CIDE</span> se reserva el derecho a modificar y actualizar en cualquier momento, y sin necesidad de previo aviso, los presentes términos y condiciones de uso, los cuales entrarán en vigor a
          partir de su fecha de publicación.
          <br />
          <br />
          <br />
          <br />
          <span className="font-bold">JOSE JULIAN CARVAJAL VARGAS</span>
          <br />
          Director Ejecutivo (Representante Legal)
          <br />
          CIDE - Centro de Integración y Desarrollo Empresarial
          <br />
          NIT 810.005.090-4
        </div>
      </section>
    </Fragment>
  );
};

export default UseTerms;
