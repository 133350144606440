import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

import { useState } from "react";

import Home from "./pages/Home";
import Economy from "./pages/Economy";
import Education from "./pages/Education";
import Health from "./pages/Health";
import Sport from "./pages/Sport";
import Environment from "./pages/Environment";

import Cookies from "./pages/Cookies";
import UseTerms from "./pages/UseTerms";
import RegimenTributario from "./pages/RegimenTributario";
import Credits from "./pages/Credits";

import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [theme, setTheme] = useState("light");

  return (
    <Router>
      <NavBar theme={theme} setTheme={setTheme} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home theme={theme} />} />
        <Route path="/economia" exact element={<Economy theme={theme} />} />
        <Route path="/educacion" exact element={<Education theme={theme} />} />
        <Route path="/salud" exact element={<Health theme={theme} />} />
        <Route path="/deporte" exact element={<Sport theme={theme} />} />
        <Route path="/medioambiente" exact element={<Environment theme={theme} />} />

        <Route path="/aviso-de-cookies" exact element={<Cookies theme={theme} />} />
        <Route path="/terminos-de-uso" exact element={<UseTerms theme={theme} />} />
        <Route path="/regime-tributario-especial" exact element={<RegimenTributario theme={theme} />} />
        <Route path="/creditos-sitio-web" exact element={<Credits theme={theme} />} />

      </Routes>
      <Footer theme={theme} />
    </Router>
  );
}

export default App;
