import React, { Fragment } from "react";

import EducationAcordion from "../components/EducationAcordion";
import BannerHelp from "../components/BannerHelp";

const Education = ({ theme }) => {
  return (
    <Fragment>
      <section className="h-screen bg-center bg-cover bg-no-repeat bg-education_image text-white">
        <div className="h-full py-12 flex items-center pl-20 bg-gradient-to-r from-black">
          <p className="text-3xl lg:text-7xl font-bold">
            Ciencia, Tecnología <br />e Innovación que <br />
            genera Bienestar Social
          </p>
        </div>
      </section>

      <section className="bg-white dark:bg-dark dark:text-white py-8 lg:py-14">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-5">
          <div className="flex flex-col lg:w-1/5 justify-center items-center">
            <div className="h-20 w-20 rounded-full overflow-hidden">
              <img src={require("../assets/images/nelson_mandela.jpg")} alt="Nelson Mandela" className="h-full w-full" />
            </div>
            <p className="font-bold">Nelson Mandela</p>
            <p>Ex presidente de Sudáfrica</p>
          </div>
          <p className="text-sm lg:text-3xl font-bold lg:w-2/5 text-center">"La educación es el arma más poderosa que puedes usar para cambiar el mundo"</p>
          <div className="h-14 lg:h-24 lg:w-1/5 flex justify-center">
            <img src={theme === "dark" ? require("../assets/images/dark/firma_nelson_mandela_white.png") : require("../assets/images/firma_nelson_mandela.png")} alt="Firma Henry Ford" className="h-full w-full lg:w-2/3" />
          </div>
        </div>
      </section>

      <section>
        <div className="bg-gray-50 dark:bg-semi-dark dark:text-white flex justify-center p-8 lg:py-14">
          <p className="w-full lg:w-3/5 text-sm lg:text-3xl text-center">
            Es claro que la educación reduce los niveles de desigualdad, impactando de forma positiva el bienestar social a través del acceso a mejores empleos, mayores ingresos, disminución de los índices de criminalidad, incremento de la
            creatividad para la resolución de problemas, entre muchos otros beneficios.
            <br />
            <br />
            CIDE le apuesta a proyectos que mejoren la inclusión y el acceso a la educación a todos los miembros de la comunidad, sin exclusión alguna por sexo, raza, edad, creencia religiosa, ni cualquier otro factor de discriminación.
          </p>
        </div>
      </section>

      <section className="bg-white dark:bg-dark overflow-hidden">
        <div className="container mx-auto pr-6 pl-3 sm:pr-0 sm:pl-0">
          <EducationAcordion theme={theme} />
        </div>
      </section>

      <section>
        <BannerHelp />
      </section>
    </Fragment>
  );
};

export default Education;
