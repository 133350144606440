import { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <div className={`${id === open ? "bg-blue-cide text-white" : "bg-gray-100 text-black"} h-9 w-9 rounded-full flex items-center font-light justify-center transition-transform`}>
      {open === id ? <i className="fa-solid fa-xmark"></i> : <i className="fas fa-plus"></i>}
    </div>
  );
}

function Body({ id }) {
  if (id === 1) {
    return (
      <div className="font-century-gothic text-sm lg:text-lg text-black dark:text-white space-y-5 text-right">
        <h1 className="font-bold texl-xl lg:text-4xl">Conferencia</h1>
        <p>
          Doctores en Educación Física nos apoyaron para orientar a cientos de padres de familia, de varias ciudades de Colombia, en temas claves para la formación de sus hijos, resolviendo preguntas muy pertinentes como las siguientes: ¿cómo
          potencializar el deporte en sus hijos?, de acuerdo a la edad ¿cuál debe ser la exigencia deportiva?, ¿cómo identificar si su hijo cuenta con habilidades para practicar alguna actividad deportiva en modalidad de alto rendimiento?, entre
          muchas otras.
        </p>

        <div className="flex sm:justify-between flex-col sm:flex-row text-xl gap-y-3">
          <div className="flex space-x-6">
            <p>
              Medios&nbsp;&nbsp;<i className="fa-solid fa-book"></i>
            </p>
            <div className="space-x-5 animate-pulse">
              <a href="https://www.facebook.com/conferenciaregional" className="hover:font-bold hover:text-blue-cide" title="CIDE: Conferencia Regional" target="_blank" rel="noreferrer">
                1
              </a>
            </div>
          </div>

          <p className="sm:text-right">
            <a href="/#formulario-contacto" className="underline hover:text-blue-cide font-bold">
              Más información
            </a>
          </p>
        </div>

        <div className="flex justify-center">
          <div className="flex -space-x-12 h-52">
            <img className="w-40 h-40 border-4 shadow-2xl border-white rounded-full dark:border-gray-800" src={require("../assets/images/conferencia3.jpg")} alt="Conferencia CIDE" />
            <div className="flex items-end">
              <img className="w-40 h-40 border-4 border-white rounded-full dark:border-gray-800 z-0" src={require("../assets/images/conferencia2.jpg")} alt="Conferencia CIDE" />
            </div>
            <img className="w-40 h-40 border-4 shadow-2xl border-white rounded-full dark:border-gray-800" src={require("../assets/images/conferencia1.jpg")} alt="Conferencia CIDE" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="font-century-gothic text-sm lg:text-lg text-black dark:text-white space-y-5 text-right">
        <h1 className="font-bold texl-xl lg:text-4xl">Monte</h1>
        <p>Nos encontramos trabajando en una herramienta que ayudará a miles de niños en Colombia a tener la posibilidad de practicar los deportes de su preferencia.</p>

        <div>
          <p className="sm:text-right">
            <a href="/#formulario-contacto">
              <button className="bg-blue-cide hover:bg-blue-700 rounded-full py-1 px-4 text-white font-semibold text-sm lg:text-lg">Quiero apoyar el proyecto</button>
            </a>
          </p>
        </div>

        <div className="flex justify-center">
          <div className="flex -space-x-12 h-52">
            <img className="w-40 h-40 border-4 shadow-2xl border-white rounded-full dark:border-gray-800 object-cover object-left" src={require("../assets/images/sport_1.jpg")} alt="Monte" />
            <div className="flex items-end">
              <img className="w-40 h-40 border-4 border-white rounded-full dark:border-gray-800 z-0" src={require("../assets/images/sport_2.jpg")} alt="Monte" />
            </div>
            <img className="w-40 h-40 border-4 shadow-2xl border-white rounded-full dark:border-gray-800" src={require("../assets/images/sport_3.jpg")} alt="Monte" />
          </div>
        </div>
      </div>
    );
  }
}

export default function SportAcordion({ theme }) {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? value : value);
  };

  return (
    <Fragment>
      <div className="flex flex-col lg:flex-row w-full h-full gap-x-5">
        <section className="w-full lg:w-3/5 h-full">
          <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
            <div className="hidden lg:block h-full">
              <AccordionBody>
                <Body id={1} />
              </AccordionBody>
            </div>
          </Accordion>
          <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
            <div className="hidden lg:block">
              <AccordionBody>
                <Body id={2} />
              </AccordionBody>
            </div>
          </Accordion>
        </section>

        <section className="w-full lg:w-2/5 space-y-5">
          <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
            <section className="shadow-lg rounded-lg px-8 border-2 border-gray-100">
              <AccordionHeader onClick={() => handleOpen(1)}>
                <div className="flex items-center space-x-5 h-full sm:h-32 w-full my-4">
                  <div className="w-1/3 flex justify-center">
                    <img src={require("../assets/images/logo_cide_skyblue.png")} alt="Cide" className="w-full" />
                  </div>
                  <div className="h-full w-2/3">
                    <h1 className="text-black dark:text-white text-xl lg:text-3xl font-century-gothic text-left">Conferencia</h1>
                    <p className="text-black dark:text-white text-sm lg:text-base font-century-gothic text-left font-light">Expertos en Educación Física explican cómo gestionar actividades deportivas en niños y adolescentes</p>
                  </div>
                </div>
              </AccordionHeader>
            </section>
            <section className="block lg:hidden my-4">
              <AccordionBody>
                <Body id={1} />
              </AccordionBody>
            </section>
          </Accordion>

          <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
            <section className="shadow-lg rounded-lg px-8 border-2 border-gray-100">
              <AccordionHeader onClick={() => handleOpen(2)}>
                <div className="flex items-center space-x-5 h-full sm:h-32 w-full my-4">
                  <div className="w-1/3 flex justify-center">
                    <img src={theme === "dark" ? require("../assets/images/dark/logo_monte_white.png") : require("../assets/images/logo_monte.png")} alt="Monte" className="w-full" />
                  </div>
                  <div className="h-full w-2/3">
                    <h1 className="text-black dark:text-white text-xl lg:text-3xl font-century-gothic text-left">Monte</h1>
                    <p className="text-black dark:text-white text-sm lg:text-base font-century-gothic text-left font-light">Estamos construyendo una solución digital para promover el acceso a las actividades deportivas de la ciudad</p>
                  </div>
                </div>
              </AccordionHeader>
            </section>

            <section className="block lg:hidden my-4">
              <AccordionBody>
                <Body id={2} />
              </AccordionBody>
            </section>
          </Accordion>
        </section>
      </div>
    </Fragment>
  );
}
