import React, { Fragment } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
//breakepoints
import {
    Autoplay,
} from "swiper";



const SwiperCompanies = () => {

    return (
        <Fragment>
            <Swiper

                autoplay={{ delay: 300, disableOnInteraction: false }}
                modules={[Autoplay]}
                className="swiper-2"
                loop={true}
                breakpoints={{

                    //when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },

                    // when window width is >= 640px
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },

                    // when window width is >= 768px
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 40
                    },

                    // when window width is >= 1024px
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 20
                    }
                }}
            >
                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_alcaldia_manizales.png')} alt="Alcaldia de Manizales" className="h-24" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_gobernacion_caldas.png')} alt="Gobernación de Caldas" className="h-24" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_codechoco.png')} alt="Codechoco" className="h-28" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_alcaldia_riosucio.png')} alt="Alcaldia de RioSucio" className="h-20" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_alcaldia_supia.jpg')} alt="Alcaldia de Supía" className="h-20" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_codechoco.png')} alt="Codechoco" className="h-28" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_aguas.png')} alt="Aguas de Manizales" className="h-28" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_alcaldia_neira.png')} alt="Alcaldía de Neira" className="h-24" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_angiohealth.png')} alt="AngioHealth" className="h-14" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_cda.jpg')} alt="CDA Las Motos" className="h-28" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_invensa.png')} alt="INVENSA" className="h-28" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_palestina.png')} alt="Palestina" className="h-20" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_sps.png')} alt="San Andrés Port Society" className="h-32" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_viterbo.png')} alt="Municipio de Viterbo" className="h-28" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_agroguachal.png')} alt="Agroguachal" className="h-28" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_fenalco.png')} alt="Fenalco" className="h-28" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_flores.jpg')} alt="Flores Nueva Nueva" className="h-24" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_infi.png')} alt="Infi Manizales" className="h-28" />
                </SwiperSlide>

                <SwiperSlide className="swiper-slide-2">
                    <img src={require('../assets/images/logo_victoria.jpg')} alt="Victoria Moncada" className="h-32" />
                </SwiperSlide>

            </Swiper>

        </Fragment>


    )
}

export default SwiperCompanies;