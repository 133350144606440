import React, { Fragment } from 'react'

const Cookies = () => {
    return (
        <Fragment>
            <section className="bg-center bg-cover bg-no-repeat bg-cookies_image text-white">
                <div className="h-full py-28 sm:py-32 lg:py-52 2xl:py-72 flex items-center pl-20 bg-gradient-to-r from-black">
                    <p className="text-3xl lg:text-7xl font-bold">Aviso de Cookies</p>
                </div>
            </section>

            <section>
                <div className="bg-white dark:bg-dark dark:text-white flex justify-center p-8 lg:py-14">
                    <p className="w-full lg:w-4/5 text-sm lg:text-3xl text-center">
                        Este sitio web y sus páginas internas son propiedad de CENTRO DE INTEGRACION Y DESARROLLO
                        EMPRESARIAL con NIT 810.005.090-4 y cuentan con sistema de cookies para el registro de sus
                        visitantes y sus actividades digitales dentro del sitio, con el propósito de: mejorar su experiencia de
                        usuario, efectuar actividades comerciales como el suministro de productos, servicios, ofertas, y/o
                        realizar procesos administrativos, jurídicos u operacionales propios del objeto misional de nuestra
                        entidad. Al navegar en nuestro sitio web, sus páginas internas y sus diferentes enlaces, así como al
                        hacer clic en botones y registrar de forma electrónica datos personales, usted declara que conoce
                        nuestra política de tratamiento de datos personales disponible en: <a href="https://www.politicadeprivacidad.co/politica/cide" className="underline text-blue-cide">www.politicadeprivacidad.co/politica/cide</a>, también declara que conoce sus derechos como titular de la información y que
                        autoriza de manera libre, voluntaria, previa, explícita, informada e inequívoca a CENTRO DE
                        INTEGRACION Y DESARROLLO EMPRESARIAL con NIT 810.005.090-4 para realizar el tratamiento de sus
                        datos personales de acuerdo con la información suministrada en dicha política.


                    </p>
                </div>
            </section>
        </Fragment>
    )
}

export default Cookies;